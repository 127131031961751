<template>
  <div class="pageContainer targetPage flexCloumn">
    <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
      <div class="flexStart flex_1" style="flex-wrap:wrap;">
        <el-input placeholder="学号或姓名" v-model="searchForm.keyword" size="small" class="input-with-select"
          style="width:200px;" clearable></el-input>
        <el-select v-model="searchForm.status" size="small" clearable placeholder="请选择" style="margin-left: 10px;">
          <el-option label="未抽查" :value="0"></el-option>
          <el-option label="已抽查" :value="1"></el-option>
        </el-select>

        <el-button icon="el-icon-search" style="margin-left:10px" size="small" type="primary"
          @click="() => { page.current_page = 1; getList()}">搜索</el-button>
      </div>

<!--      <div class="flexStart"
        style="color: #3378fa;font-size: 14px;font-weight: 700;margin: 0 5px 0 30px;cursor: pointer;">

        <el-button v-if="$store.state.config.sync_data" size="mini" icon="el-icon-refresh" @click="tongbuJava"
          type="primary" plain style="margin-left: 10px;">迁移老版本</el-button>
      </div>-->
    </el-row>
    <div class="dividerBar"></div>
     <el-table v-tableFit :data="DataList" height="100%" size="small" stripe border style="width:fit-content;width:100%;">
      <el-table-column type="index" width="55" label="序号"></el-table-column>
      <el-table-column prop="stu_code" label="学号"></el-table-column>
      <el-table-column prop="stu_name" label="学生姓名"></el-table-column>
      <el-table-column prop="status" label="抽查状态">
        <template v-slot="scope">
          <el-tag v-if="scope.row.status" type="success" size="small">已抽查</el-tag>
          <el-tag v-else type="warning" size="small">未抽查</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="teacher_name" label="抽查人"></el-table-column>
      <el-table-column prop="udate" label="抽查时间">
        <template v-slot="scope">
          {{scope.row.status ? scope.row.udate : ''}}
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="120">
        <template slot-scope="scope">
          <el-tag class="pointer" type="success" size="mini" @click="viewInfo(scope.row)">编辑</el-tag>
          <el-tag class="pointer ml10" type="danger" size="mini" @click="deleteJh(scope.row)">删除</el-tag>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
      :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
      :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
    </el-pagination>


    <el-dialog title="抽查记录修改" :visible.sync="dialogshow" destroy-on-close :close-on-click-modal="false">
      <el-form :model="EditItem" label-width="120px">
        <el-form-item label="学生姓名" >
          <el-input v-model="EditItem.stu_name" autocomplete="off" disabled></el-input>
        </el-form-item>
        <el-form-item label="抽查人">
          <el-input v-model="$store.state.teacher.username" autocomplete="off" disabled></el-input>
        </el-form-item>
        <el-form-item label="抽查状态">
          <el-select v-model="EditItem.status" placeholder="请选择抽查状态">
            <el-option label="未抽查" :value="0"></el-option>
            <el-option label="已抽查" :value="1"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogshow = false">取 消</el-button>
        <el-button type="primary" @click="saveJh">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import tchtree from "../com/tchTree.vue";
export default {
  components: {
    tchtree
  },
  data() {
    return {
      thisTeacherid: 0,
      dialogshow: false,
      date_range_value: "",
      searchForm: {
        class_ids: "",
        keyword: ""
      },
      EditItem: {
        id: 0,
        status: 0,
        stu_name: "",
      },
      DataList: [],
      listForm: [],
      listState: [],
      ClassList: [],
      page: {
        count: 0,
        current_page: 1,
        per_page: 50,
        total_page: 0
      }
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.$http
        .post("/api/stu_check_record_list", {
          page: this.page.current_page,
          pagesize: this.page.per_page,
          keyword: this.searchForm.keyword,
          status: this.searchForm.status,
        })
        .then(res => {
          this.DataList = res.data.data;
          this.page = res.data.page;
        });
    },
    deleteJh(row) {
      this.$confirm("此操作将删除该条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$http
            .post("/api/stu_check_record_delete", { id: row.id })
            .then(res => {
              this.$message({
                type: "success",
                message: "删除成功!"
              });
              this.getList()
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    handlePageChange(page) {
      this.page.current_page = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.page.current_page = 1;
      this.page.per_page = val;
      this.getList()
    },
    addNew() {
      this.EditItem.id = 0;
      this.dialogshow = true;
    },
    saveJh() {
      this.$http.post('/api/stu_check_record_edit', this.EditItem).then(res => {
        this.$message.success('保存成功')
        this.dialogshow = false;
        this.getList()
      })
    },
    viewInfo(row) {
      this.EditItem = { ...row };
      this.dialogshow = true;
    },
    tongbuJava() {
      this.$confirm('请确保JAVA版本已存在？是否继续同步?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let loadingInstance = this.$loading({
          lock: true,
          text: "已发送请求，请稍等...",
          spinner: 'el-icon-loading'
        })
        this.$http.post("/api/sync_back_to_school", {}).then(res => {
          loadingInstance.close()
          this.$message.success('后台同步中，请刷新查看')
          this.getList();
        }).catch(err => {
          this.$message.error('同步失败')
          loadingInstance.close()
        })

      }).catch(() => {

      });
    }
  }
};
</script>

<style scoped="scoped">
.spuser {
  display: inline-block;
  border: 1px solid rgb(255, 148, 62);

  padding: 2px;
  margin-right: 10px;
  margin-bottom: 5px;
  border-radius: 4px;
  padding: 4px 10px;
}

.spuser img {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
}
</style>
